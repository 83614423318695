var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.$t("task.auto_assign.title"),
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("div", { staticClass: "selected-text" }, [
            _vm._v(
              _vm._s(
                _vm.$t("task.auto_assign.num_selected", [_vm.tasks.length])
              )
            ),
          ]),
          _c("div", { staticClass: "selected-text d-inline-block mr-2 mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.$t(
                  _vm.isGenericStaff
                    ? "task.auto_assign.num_generic_staff_selected"
                    : "task.auto_assign.num_staff_selected",
                  [
                    _vm.staff.length,
                    !_vm.hideStaffLengthLabel
                      ? _vm.$t("task.auto_assign.allstaff")
                      : "",
                  ]
                )
              )
            ),
          ]),
          _vm.canList()
            ? _c(
                "b-button",
                {
                  staticClass: "staff-button",
                  attrs: { variant: "secondary" },
                  on: { click: _vm.chooseStaff },
                },
                [_vm._v(_vm._s(_vm.$t("button.choose_staff")))]
              )
            : _vm._e(),
          _c("hr", { staticClass: "solid" }),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg", name: "allow_over_alloc" },
              model: {
                value: _vm.settings.skip_already_started,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "skip_already_started", $$v)
                },
                expression: "settings.skip_already_started",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("task.auto_assign.skip_already_started")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg", name: "allow_over_alloc" },
              model: {
                value: _vm.settings.skip_already_assigned,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "skip_already_assigned", $$v)
                },
                expression: "settings.skip_already_assigned",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("task.auto_assign.skip_already_assigned")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg", name: "allow_over_alloc" },
              model: {
                value: _vm.settings.replace_generic_only,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "replace_generic_only", $$v)
                },
                expression: "settings.replace_generic_only",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("task.auto_assign.replace_generic_only")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg", name: "allow_over_alloc" },
              model: {
                value: _vm.settings.allow_over_alloc,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "allow_over_alloc", $$v)
                },
                expression: "settings.allow_over_alloc",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("recommendation.allow_over_alloc")) + " "
              ),
            ]
          ),
          _c("hr", { staticClass: "solid" }),
          _c("div", { staticClass: "skill-match" }, [
            _vm._v(_vm._s(_vm.$t("task.auto_assign.skill_matching"))),
          ]),
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg", name: "match_staff_based_on_skills" },
              model: {
                value: _vm.settings.match_staff_based_on_skills,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "match_staff_based_on_skills", $$v)
                },
                expression: "settings.match_staff_based_on_skills",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recommendation.match_staff_based_on_skills")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "indent setting-checkbox",
              attrs: {
                disabled: true,
                size: "lg",
                name: "include_staff_exact_skill",
              },
              model: {
                value: _vm.settings.include_staff_exact_skill,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "include_staff_exact_skill", $$v)
                },
                expression: "settings.include_staff_exact_skill",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recommendation.include_staff_exact_skill")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "indent setting-checkbox",
              attrs: {
                disabled: !_vm.settings.match_staff_based_on_skills,
                size: "lg",
                name: "include_staff_higher_skill",
              },
              model: {
                value: _vm.settings.include_staff_higher_skill,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "include_staff_higher_skill", $$v)
                },
                expression: "settings.include_staff_higher_skill",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recommendation.include_staff_higher_skill")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "indent-two setting-checkbox",
              attrs: {
                disabled:
                  !_vm.settings.match_staff_based_on_skills ||
                  !_vm.settings.include_staff_higher_skill,
                size: "lg",
                name: "adjust_task_duration_higher",
              },
              model: {
                value: _vm.settings.adjust_task_duration_higher,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "adjust_task_duration_higher", $$v)
                },
                expression: "settings.adjust_task_duration_higher",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recommendation.adjust_task_duration")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "indent setting-checkbox",
              attrs: {
                disabled: !_vm.settings.match_staff_based_on_skills,
                size: "lg",
                name: "include_staff_lower_skill",
              },
              model: {
                value: _vm.settings.include_staff_lower_skill,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "include_staff_lower_skill", $$v)
                },
                expression: "settings.include_staff_lower_skill",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recommendation.include_staff_lower_skill")) +
                  " "
              ),
            ]
          ),
          _c(
            "b-form-checkbox",
            {
              staticClass: "indent-two setting-checkbox",
              attrs: {
                disabled:
                  !_vm.settings.match_staff_based_on_skills ||
                  !_vm.settings.include_staff_lower_skill,
                size: "lg",
                name: "adjust_task_duration_lower",
              },
              model: {
                value: _vm.settings.adjust_task_duration_lower,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "adjust_task_duration_lower", $$v)
                },
                expression: "settings.adjust_task_duration_lower",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recommendation.adjust_task_duration")) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.staffSelectorShow
        ? _c("StaffSelectorModalForAdmin", {
            attrs: {
              show: _vm.staffSelectorShow,
              projectIds: _vm.projectId ? [_vm.projectId] : [],
              companies: _vm.companies,
              selectedStaff: _vm.staffUuids,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.staffSelectorShow = $event
              },
              ok: _vm.staffSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }