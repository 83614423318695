<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="md" :title="$t('staff.title_work_effort')" footer-class="footerClass" 
      no-close-on-backdrop  content-class="shadow"
      @hidden="hidden">
      
      <b-row class="d-none d-sm-flex mb-sm-2">
        <b-col cols="4" >{{ $t('staff.field.staff') }}</b-col>
        <b-col cols="4" >{{ $t('staff.field.effortToDate') }}</b-col>
        <b-col cols="4" >{{ $t('staff.field.newEffort') }}</b-col>
      </b-row>
      
      <div class="work-effort">
        <b-row v-for="(item, index) of workEffortList" :key="index" class="effort-item">
          <b-col cols="12" sm="4" class="pr-sm-0 mb-1">
            <b-form-input size="sm" v-model="item.name" readonly/>
          </b-col>
          <b-col cols="12" sm="4" class="pr-sm-0 mb-1">
            <b-input-group>
              <b-form-input size="sm" v-model="item.durationDisplay" class="effort-to-date-input" readonly/>
              <b-input-group-append v-if="!readOnly">
                <b-input-group-text :id="`BTN_CLEAR_EFFORT_${id}_${index}`" class="effort-remove-icon-container" @click="preEffortToDateClear(index)">
                <font-awesome-icon :icon="['far', 'xmark']" class="effort-remove-icon"/>
                <b-popover :target="`BTN_CLEAR_EFFORT_${id}_${index}`" triggers="hover">
                  {{ $t('task.button.clear_effort') }}
                </b-popover>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="10" sm="3" class="mb-2">
            <b-form-input :disabled="readOnly" size="sm" v-model="item.newEffort" ref="newEffort"></b-form-input>
          </b-col>
          <b-col v-if="!readOnly" cols="2" sm="1" class="pl-0">
            <b-button :id="`BTN_COMMIT_${id}_${index}`" class="working-action" size="sm" variant="secondary-outline" @click="commitNewEffort(index)"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
            <b-popover :target="`BTN_COMMIT_${id}_${index}`" triggers="hover">
              {{ $t('button.commit') }}
            </b-popover>
          </b-col>
        </b-row>
      </div>
      <b-row class="mb-1">
        <b-col cols="12" sm="4" class="pr-sm-0">
          <div class="float-sm-right mb-1 mb-sm-0 pt-sm-1">
            {{ childTaskEfforts > 0? $t('staff.task_total') : $t('staff.total_work_effort') }}
          </div>
        </b-col>
        <b-col cols="12" sm="4" class="pr-sm-0">
          <b-form-input size="sm" :value="taskEffort" disabled/>
        </b-col>
      </b-row>
      <template v-if="childTaskEfforts > 0">
        <b-row class="mb-1">
          <b-col cols="12" sm="4" class="pr-sm-0">
            <div class="float-sm-right mb-1 mb-sm-0 pt-sm-1">
              {{ $t('staff.child_task_efforts') }}
            </div>
          </b-col>
          <b-col cols="12" sm="4" class="pr-sm-0">
            <b-form-input size="sm" :value="childTaskEffortDisplay" disabled/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="4" class="pr-sm-0">
            <div class="float-sm-right mb-1 mb-sm-0 pt-sm-1">
              {{ $t('staff.total_work_effort') }}
            </div>
          </b-col>
          <b-col cols="12" sm="4" class="pr-sm-0">
            <b-form-input size="sm" :value="totalWorkEffort" disabled/>
          </b-col>
        </b-row>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!readOnly" size="sm" variant="success" @click="preOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('staff.confirmation.title_clear_effort')"
        v-model="confirmClearEffortShow"
        @hidden="confirmClearEffortShow=false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('staff.confirmation.clear_effort') }}
      </div>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="effortToDateClear">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('staff.confirmation.title_uncommitted_effort')"
        v-model="confirmCommitNewEffortShow"
        @cancel="ok"
        @hidden="confirmCommitNewEffortShow=false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t('staff.confirmation.uncommitted_effort') }}
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="confirmCommitNewEffortOk">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { 
  strRandom, maxDuratioUnit
} from '@/helpers';

import { analyzeDurationAUM, convertDurationToDisplay, convertDisplayToDuration, extractDurationConversionOpts } from '@/helpers/task-duration-process';

export default {
  name: 'StaffWorkEffortModal',
  components: {
  },
  props: {
    childTaskEfforts: {
      type: Number,
      default: 0
    },
    workEfforts: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      required: true
    },
    startValue: {
      type: String,
      default: null
    },
    highlightAllOnFocus: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: `TSTAFF_WORK_EFFORT_${strRandom(5)}`,
      modalShow: false,
      workEffortList: [
        // {
        //   id: 'xxx', name: 'John Smith', duration: 960, duratioAUM: 'D', newEffort: null, durationDisplay: '2D'
        // },
        // {
        //   id: null, name: 'Alexandar', duration: 60, duratioAUM: 'h', newEffort: null, durationDisplay: '1h'
        // }
      ],
      confirmClearEffortShow: false,
      toClearEffortIndex: null,

      confirmCommitNewEffortShow: false,
      durationConversionOpts: {}
    }
  },
  created() {
    this.getDurationConversionOpts();
    this.updateShow(this.show);
    this.updateWorkEfforts(this.workEfforts);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    show(newValue) {
      this.updateShow(newValue);
      this.updateWorkEfforts(this.workEfforts);
      if(newValue) {
        this.init();
      }
    },
  },
  computed: {
    taskEffort() {
      let total = 0;
      let unit = 'm';
      for(const item of this.workEffortList) {
        total += item.duration || 0;
        const { unit: itemUnit } = analyzeDurationAUM(item.durationAUM);
        unit = maxDuratioUnit(unit, itemUnit || 'm');
      }
      return convertDurationToDisplay(total, unit, this.durationConversionOpts);
    },
    totalWorkEffort() {
      let total = 0;
      let unit = 'D';
      for(const item of this.workEffortList) {
        total += item.duration || 0;
        const { unit: itemUnit } = analyzeDurationAUM(item.durationAUM);
        unit = maxDuratioUnit(unit, itemUnit || 'm');
      }
      return convertDurationToDisplay(total + this.childTaskEfforts, unit, this.durationConversionOpts);
    },
    childTaskEffortDisplay() {
      return convertDurationToDisplay(this.childTaskEfforts, 'D', this.durationConversionOpts);
    }
  },
  methods: {
    init() {
      setTimeout(() => {
        if(this.$refs && this.$refs.newEffort && this.$refs.newEffort.length > 0) {
          if (this.startValue != null && this.workEffortList.length > 0) {
            this.workEffortList[0].newEffort = this.startValue;
          }
          if (this.highlightAllOnFocus) {
            this.$refs.newEffort[0].select();
          }
          this.$refs.newEffort[0].focus();
        }
      }, 100);
    },
    hidden() {
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    updateShow(newValue) {
      this.modalShow = newValue;
    },
    updateWorkEfforts(newValue) {
      const list = newValue? newValue.map(i => {
        return { uuId: i.uuId, name: i.name, duration: i.duration, durationAUM: i.durationAUM, durationDisplay: convertDurationToDisplay(i.duration, i.durationAUM, this.durationConversionOpts), newEffort: null }
      }) : [];
      this.workEffortList.splice(0, this.workEffortList.length, ...list);
    },
    confirmCommitNewEffortOk() {
      for(const workEffort of this.workEffortList) {
        if(workEffort.newEffort) {
          const { unit } = analyzeDurationAUM(workEffort.durationAUM);
          const { duration, durationAUM, durationDisplay } = this.addEffort(workEffort.duration, unit, workEffort.newEffort);
          workEffort.duration = duration;
          workEffort.durationAUM = durationAUM;
          workEffort.durationDisplay = durationDisplay;
          workEffort.newEffort = null;
        }
      }
      this.confirmCommitNewEffortShow = false;
      this.ok();
      
    },
    preOk() {
      const hasNewEffort = this.workEffortList.some(i => i.newEffort != null);
      if(hasNewEffort) {
        this.confirmCommitNewEffortShow = true;
      } else {
        this.ok();
      }
    },
    ok() {
      this.$emit('ok', this.workEffortList.map(i => {
        return { uuId: i.uuId, duration: i.duration, durationAUM: i.durationAUM }
      }));
      this.$emit('update:show', false);
    },
    effortToDateClear() {
      this.workEffortList[this.toClearEffortIndex].duration = 0;
      this.workEffortList[this.toClearEffortIndex].durationAUM = null;
      this.workEffortList[this.toClearEffortIndex].durationDisplay = null;
      this.confirmClearEffortShow = false;
    },
    preEffortToDateClear(index) {
      this.toClearEffortIndex = index;
      this.confirmClearEffortShow = true;
    },
    commitNewEffort(index) {
      const workEffort = this.workEffortList[index];
      const { unit } = analyzeDurationAUM(workEffort.durationAUM);
      const { duration, durationAUM, durationDisplay } = this.addEffort(workEffort.duration, unit, workEffort.newEffort);
      workEffort.duration = duration;
      workEffort.durationAUM = durationAUM;
      workEffort.durationDisplay = durationDisplay;
      workEffort.newEffort = null;
    },
    addEffort(duration, unit, newEffort) {
      let _duration = duration || 0;
      const { unit: newEffortUnit } = analyzeDurationAUM(newEffort);
      const { value } = convertDisplayToDuration(newEffort, this.durationConversionOpts);
      _duration += value;
      const preferredUnit = maxDuratioUnit(unit, newEffortUnit);
      const durationDisplay = convertDurationToDisplay(_duration, preferredUnit, this.durationConversionOpts);
      return { 
        duration: _duration, 
        durationAUM: preferredUnit,
        durationDisplay: durationDisplay
      }
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        console.error(e) //eslint-disable-line no-console
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.work-effort {
  .effort-to-date-input {
   border-right: 0; 
   padding-right: 0;
  //  background-color: transparent;
  }

  .effort-remove-icon-container {
    // background-color: transparent;
    border-left: 0;
    cursor: pointer;
  }

  .effort-remove-icon-container {
    // &, &[disabled]:active {
    //   background-color: transparent;
    // }

    &:active {
      background-color: var(--cell-button-active);
    }
    
    &:active:focus {
      box-shadow: 0 0 0 0.2rem var(--cell-button-active);
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: var(--black);
    }
  }

  .effort-item {
    margin-bottom: 0.5rem;
  }

  .effort-action {
    &, &[disabled]:active {
      background-color: transparent;
      border: 0;
    }

    &:active {
      background-color: var(--cell-button-active);
    }
    
    &:active:focus {
      box-shadow: 0 0 0 0.2rem var(--cell-button-active);
    }
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: var(--black);
    }
  }
}
</style>